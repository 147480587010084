/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
.success-snackbar {
  --mdc-snackbar-supporting-text-color: var(--fuse-on-primary) #{'!important'};
  --mdc-snackbar-container-color: var(--fuse-primary) #{'!important'};

  .mat-simple-snackbar-action {
    --mdc-snackbar-supporting-text-color: var(--fuse-on-primary) #{'!important'};
  }
  --mat-mdc-snack-bar-button-color: var(--fuse-on-primary) #{'!important'};
}

.error-snackbar {
  --mdc-snackbar-supporting-text-color: var(--fuse-on-primary) #{'!important'};
  --mdc-snackbar-container-color: var(--fuse-warn) #{'!important'};

  .mat-simple-snackbar-action {
    --mdc-snackbar-supporting-text-color: var(--fuse-on-primary) #{'!important'};
  }
  --mat-mdc-snack-bar-button-color: var(--fuse-on-primary) #{'!important'};
}
